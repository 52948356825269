/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
// import i18n from 'i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import TextBasePageContainer from '@containers/TextBasePageContainer'
// helper
// style, img, elm
// const
// const escapeStringRegexp = require("escape-string-regexp")
// const lang = i18n.languages[0]

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
const Legal = ({data}) => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   */
	return  <LayoutContainer>
				<Seo title={t('title.legal')} />
  			<TextBasePageContainer className="legal" title="legal">
  				<div dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }} />
  			</TextBasePageContainer>
			</LayoutContainer>
}

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 */
export default Legal

/**
 * export
 *
 * @version 0.1 (2021.05.14 : usayama)
 */
export const query = graphql`
	query LegalQuery {
	  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(\/ja\/legal)/"}}) {
	    edges {
	      node {
	        id
	        html
	      }
	    }
	  }
	}
`
/*
: todo
export const query = graphql`
	query MyQuery {
	  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/${escapeStringRegexp(pagePath)}/"}}) {
	    edges {
	      node {
	        id
	        html
	        fileAbsolutePath
	      }
	    }
	  }
	}
`
*/