/** 
 * import : 
 * 
 * @version 0.1 (2021.05.14 : usayama_dx)
 * @version 0.2 (2021.06.22 : usayama_dx) // classNames 追加
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.14 : usayama_dx)
*/
const LabelHtml = props => {
  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.14 : usayama_dx)
   * @version 0.2 (2021.06.22 : usayama_dx) // className 追加
   */
  const { t } = useTranslation()
  const { className, label, replaces, ...attrs } = props
  const classes = classNames( className )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.14 : usayama_dx)
   * @version 0.2 (2021.06.01 : usayama_dx) // attr 追加
   * @version 0.3 (2021.06.22 : usayama_dx) // span に変更
   * @version 0.4 (2021.06.22 : usayama_dx) // className 追加
   */
  return <span className={classes} dangerouslySetInnerHTML={{ __html: (replaces) ? t(label, replaces) : t(label) }} {...attrs} />
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.05.14 : usayama_dx)
 * @version 0.2 (2021.06.22 : usayama_dx) // className 追加
*/
LabelHtml.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  replaces: PropTypes.object,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.05.14 : usayama_dx)
 * @version 0.2 (2021.06.22 : usayama_dx) // className 追加
*/
LabelHtml.defaultProps = {
  className: "",
  label: "",
  replaces: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.05.14 : usayama_dx)
*/
export default LabelHtml
