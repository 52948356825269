/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13, 05.14 : usayama)
*/
import React from 'react'
import classNames from "classnames"
import { Link } from "gatsby"
// conf, action
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
import Title from '@components/Typo/Title'
// containers
// helper
// style, img, elm
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13, 05.14 : usayama)
*/
export default function TextBasePageContainer(props) {

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
  */
  const { className, children, label, title, titleSize, ...attrs } = props
  const classes = classNames(
    "wrap",
    className,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
   * @version 0.2 (2021.06.30 : usayama) // className= に修正
   * @version 0.3 (2021.09.29 : usayama) // to_back_to_top に修正
   */
  return (
  <div className={classes} {...attrs}>
    <div className="container">
      {title && <div className="title"><Title label={title} size={titleSize} /></div>}
      {label && <LabelHtml label={label} />}
      {children}
      <div className="back-to mt-4"><Link to="/"><Label label="text.to_back_to_top" /></Link></div>
    </div>
  </div>
  )
}
