/** 
 * import : 
 * 
 * @version 0.1 (2021.04.23 : usayama)
 * @version 0.2 (2021.04.28 : usayama) // Link 追加
 * @version 0.3 (2021.05.14 : usayama) // Link を gatsby に変更, Label 追加
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from 'gatsby'
// conf, action
// components
import Label from '@components/Typo/Label'
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.04.23 : usayama)
*/
const Title = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.04.23 : usayama)
   * @version 0.2 (2021.04.26) // titleLabel 追加
   * @version 0.3 (2021.04.28 : usayama) // text, to 追加
   */
  const { 
    className, 
    size, 
    label, 
    replaces,
    text,
    to, ...attrs } = props
  const classes = classNames( className, )
  const titleLabel = label ? 'title.' + label : ''
  const TitleTag = `h${size}`

  /** 
   * return : 
   * 
   * @version 0.1 (2021.04.23 : usayama)
   * @version 0.2 (2021.04.26) // titleLabel に変更
   * @version 0.3 (2021.04.28 : usayama) // text, to 追加
   * @version 0.4 (2021.05.14 : usayama) // Label に変更
   */
  return (
    <TitleTag className={classes} {...attrs}>
    {
      to
      ? 
        <Link to={to}>
          { label && <Label label={titleLabel} replaces={replaces} /> }
          { text }
        </Link>
      : 
        <>
          { label && <Label label={titleLabel} replaces={replaces} /> }
          { text }
        </>
    }
    </TitleTag>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.04.23 : usayama)
 * @version 0.2 (2021.04.28 : usayama) // text, to 追加
*/
Title.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  replaces: PropTypes.object,
  size: PropTypes.number,
  text: PropTypes.string,
  to: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.04.23 : usayama)
 * @version 0.2 (2021.04.28 : usayama) // text, to 追加
*/
Title.defaultProps = {
  className: "",
  label: "",
  replaces: {},
  size: 1,
  text: "",
  to: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.04.23 : usayama)
*/
export default Title
